import React, { useState, useEffect } from 'react';
import { Breadcrumb, Layout, Menu, theme, Carousel, Image, Flex } from 'antd';
import { Button, Form, Input, Space, } from 'antd';
import { Card, Col, Row, Divider } from 'antd';
import { Table, List, Anchor } from 'antd';
import { Avatar, Typography } from 'antd';
import { BrowserRouter, Routes, Route, Link, useLocation, useParams } from 'react-router-dom';
import { MailFilled, MessageOutlined, HomeOutlined, MailOutlined, EnvironmentFilled, ClockCircleFilled } from '@ant-design/icons';
// asset
import logo from './asset/logo.png';
// data
import newsData from './data/news.json';
import managerData from './data/manager.json';
import jdData from './data/jd.json';
import csData from './data/compliance_statement.json'

const { Title, Paragraph, Text, } = Typography;

const { TextArea, Search } = Input;
const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const routesMap = {
  '/': '首页',
  '/info': '公司介绍',
  '/news': '新闻中心',
  '/joinus': '加入我们',
  // '/contact': '联系我们',
};
const routesMapBreadcrumb = {
  '/': '首页',
  '/info': '公司介绍',
  '/news': '新闻中心',
  // '/contact': '联系我们',
  '/joinus': '加入我们',
  '/cs': '合规声明',
};

function HeaderCom() {
  return (
    <Row >
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          height: '64px',

        }}
      >
        <Row align="middle" wrap={false} style={{ width: '100%' }}>
          <Col>
            <Link to={"/"}>
              <Image
                preview={false}
                src={logo}
                style={{ height: '48px', objectFit: 'contain' }}
              />
            </Link>
          </Col>
          <Col offset={2}>
            <Menu
              theme="dark"
              mode="horizontal"
              selectedKeys={[useLocation().pathname]}
              items={
                Object.entries(routesMap).map(([key, value]) => ({
                  key,
                  label: <Link to={key}>{value}</Link>,
                }))
              }
              style={{
                flex: 1,
                minWidth: 0,
              }}
            />
          </Col>
        </Row>
      </Header >
    </Row>
  )
}


function FooterCom() {
  const footerStyle = {
    textAlign: 'center',
    backgroundColor: "#282828",
    color: '#bfbfbf',
  };
  const footerDivider = {
    borderColor: '#bfbfbf', color: '#bfbfbf'
  };
  const copyrightStyle = {
    textAlign: 'center',
    backgroundColor: "#000000",
    color: '#8c8c8c',
    fontSize: '12px',
  };
  const itemStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
  };
  const itemLinkStyle = {
    color: '#bfbfbf',
    hovered: false,
    ':hover': {
      color: 'red',
    },
  }
  return (
    <>
      <Row justify='center' style={footerStyle}>
        <Col span={4} offset={1}>
          <List  >
            < Divider style={footerDivider} children="公司介绍" />
            <List.Item style={itemStyle}><Link style={itemLinkStyle} to="/info#aboutus">  关于我们</Link></List.Item>
            <List.Item style={itemStyle}><Link style={itemLinkStyle} to="/info#vision">  企业愿景</Link></List.Item>
            <List.Item style={itemStyle}><Link style={itemLinkStyle} to="/info#contact">  联系我们</Link></List.Item>
            <List.Item style={itemStyle}><Link style={itemLinkStyle} to="/cs">  合规声明</Link></List.Item>
          </List>
        </Col>
        <Col span={4} offset={1}>
          <List  >
            <Divider style={footerDivider} children="新闻中心" />
            <List.Item style={itemStyle}><Link style={itemLinkStyle} to="/news">  公司新闻</Link></List.Item>
          </List>
        </Col>
        <Col span={4} offset={1} >
          <List  >
            <Divider style={footerDivider} children="加入我们" />
            <List.Item style={itemStyle}><Link style={itemLinkStyle} to="/joinus">  招聘渠道</Link></List.Item>
          </List>
        </Col>
        <Col span={4} offset={1}>
          <Divider style={footerDivider} children="联系我们" />
          <Paragraph style={{ marginTop: '18px', whiteSpace: 'pre-wrap' }}><MailOutlined style={{ color: 'white' }} /> <Link to="mailto:info@enrigin.com.cn">info@enrigin.com.cn</Link></Paragraph>
          <Image
            preview={false}
            src="/QRcode.jpg"
            style={{ width: '50%' }}
          />
        </Col>
      </Row>
      <Row justify='center' style={copyrightStyle} >
        <Col ><Text style={copyrightStyle} > Copyright © {new Date().getFullYear()} 江原科技 | 备案/许可证编号：</Text>  <Link style={copyrightStyle} to={"http://beian.miit.gov.cn"}> 京ICP备2024068290号-1 </Link> <Image
          preview={false}
          src="/NISM.png"
          style={{ height: '16px', width: '100%' }}
        /> <Link style={copyrightStyle} to="https://beian.mps.gov.cn/#/query/webSearch?code=11010802044445" target="_blank">京公网安备11010802044445</Link></Col>
      </Row>
    </>
  )
}

function ContentWrapper({ stuff: Stuff }) {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const renderedStuff = Stuff ? <Stuff /> : <div>No Content Available</div>;

  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter(i => i);
  const breadcrumbItems = [
    {
      title: <Link to="/"><HomeOutlined /> 首页</Link>,
    },
    ...pathSnippets.map((snippet, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      if (/^\/news\/\d+$/.test(url)) {
        return {
          title: <Link to={url}>新闻详情</Link>,
        };
      }
      if (/^\/joinus\/\d+$/.test(url)) {
        return {
          title: <Link to={url}>招聘详情</Link>,
        };
      }
      return {
        title: <Link to={url}>{routesMapBreadcrumb[url] || snippet}</Link>,
      };
    }),
  ];
  return (
    <>
      <Content
        style={{
          padding: '0 48px',
        }}
      >
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
          items={breadcrumbItems}
        />
        <div
          style={{
            padding: 24,
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {renderedStuff}
        </div>
      </Content>
    </>
  );
}

function HomePage() {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const bannerImages = [
    '/banner-1.jpg',
    '/banner-2.jpg',
    '/banner-3.jpg',
  ];
  return (
    <>
      <Carousel autoplay>
        {bannerImages.map((src, index) => (
          <div key={index} style={{ width: '100%', height: 'auto' }}>
            <img
              src={src}
              alt={`carousel-${index}`}
              style={{
                width: '100%',
                height: 'auto',
                display: 'block',
                margin: '0 auto',
              }}
            />
          </div>
        ))}
      </Carousel>
      <Content
        style={{
          padding: '0 48px',
        }}
      >
        <HomePageNews />
      </Content>
    </>
  )
}
function HomePageNews() {
  const data = newsData.slice(0, 3)

  const cardStyle = {
    textAlign: 'center',
  };
  const cardImageStyle = {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
  };

  const [isHovered, setIsHovered] = useState(false);
  const buttonStyle = {
    backgroundColor: isHovered ? '#1890ff' : 'transparent',
    color: isHovered ? '#fff' : '#000',
    borderRadius: '20px',
    transition: 'background-color 0.8s, color 0.8s, border 0.8s',
  };
  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  };

  return (
    <>
      <div style={containerStyle}>
        <Title level={4}
          style={{
            color: '#8c8c8c',
            borderLeft: '3px solid #1677ff',
            paddingLeft: '8px',
            marginBottom: '26px',
          }}
        >新闻中心</Title>
        <Link to={"/news"} >
          <Button
            shape="round"
            type="dashed"
            style={buttonStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            查看更多+</Button>
        </Link>
      </div>
      <Row gutter={15} justify="center" >
        {data.map((item, index) => (
          <Col key={index} span={7}>
            <Link to={`/news/${item.key}`}>

              <Card
                hoverable
                style={cardStyle}
                title={item.title}
                cover={
                  <Image
                    alt="example"
                    preview={false}
                    style={cardImageStyle}
                    src={item.image || '/banner-2.jpg'}
                  />
                }
              >
                <Meta description={
                  <div style={{ textAlign: 'left' }}>
                    {item.description}
                  </div>
                } />
              </Card>
            </Link>
          </Col>
        ))}
      </Row >
      <Divider />
    </>
  )
}
function NewsPage() {
  // const IconText = ({icon, text}) => (
  //   <Space>
  //     {React.createElement(icon)}
  //     {text}
  //   </Space>
  // );
  return (
    <>
      <List
        // itemLayout="vertical"
        size="large"
        pagination={{
          onChange: (page) => {
            console.log(page);
          },
          pageSize: 3,
        }}
        dataSource={newsData}
        renderItem={(item) => (
          <List.Item
            hoverable
            key={item.title}
            // actions={[
            //   <IconText icon={LikeOutlined} text={item.likeNum} key="list-vertical-like-o" />,
            //   <IconText icon={MessageOutlined} text={item.commentNum} key="list-vertical-message" />,
            // ]}
            extra={
              <Link to={`/news/${item.key}`}><Image
                width={272}
                alt="pic"
                preview={false}
                src={item.image || '/banner-2.jpg'}
              /></Link>
            }
          >
            <List.Item.Meta
              title={<Link to={`/news/${item.key}`}><div style={{ fontSize: '20px' }}>{item.title}</div></Link>}
              description={item.description}
            />
          </List.Item>
        )}
      />

    </>
  )
}

function NewsSubPage() {
  const { id } = useParams();
  const newsItem = newsData.find(item => item.key === id);
  const paragraphStyle = {
    marginTop: '18px',
    whiteSpace: 'pre-wrap',
    textIndent: '2em'
  }

  if (!newsItem) {
    return <div>News item not found</div>;
  }
  // const paragraphs = newsItem.content.split('\n');
  return (
    <Row>

      <Col offset={2} span={16}>
        <Title level={2}>{newsItem.title}</Title>
        {/* 使用dangerouslySetInnerHTML直接渲染包含HTML的content */}
        <div dangerouslySetInnerHTML={{ __html: newsItem.content }} />
      </Col>
    </Row>

  );
};

function ContactPage() {
  const onFinish = (values) => {
    console.log(values);
  };
  const paragraphStyle = {
    whiteSpace: 'pre-wrap',
    textIndent: '2em',
    fontSize: '16px',
  }
  return (
    <>
      <Row justify="center">
        <Col >
          <Image
            preview={false}
            width="100%"
            src="/banner-2.jpg"
          />
        </Col>
      </Row>
      <Card
      >
        <Row justify="center" align="middle" >
          <Col span={15}>
            <Row align="middle" justify="space-between" wrap={false} style={{ backgroundColor: "#f0f0f0", borderRadius: '10px', paddingTop: "20px" }}>
              <Col >
                <Paragraph style={paragraphStyle}><EnvironmentFilled />上海办公室</Paragraph>
                <Paragraph style={paragraphStyle}><EnvironmentFilled />北京办公室</Paragraph>
              </Col>
              <Col offset={1}>
                <Paragraph style={paragraphStyle}>上海市浦东新区郭守敬路498号1号楼302室</Paragraph>
                <Paragraph style={paragraphStyle}>北京市海淀区知春路23号量子银座12层1209室</Paragraph>
              </Col>
              <Col span={7}>
                <Paragraph style={paragraphStyle}><MailFilled /> <Link to="mailto:info@enrigin.com.cn">info@enrigin.com.cn</Link></Paragraph>
              </Col>
            </Row>
          </Col>
          <Col span={1}><Divider type='vertical' style={{
            height: 300,
          }} /></Col>

          <Col span={5} >
            <Row justify="center" align="middle" style={{ textAlign: 'center' }}>
              <Image
                preview={false}
                src="/QRcode.jpg"
                style={{ width: '80%' }}
              />
              <Text type="secondary">扫描二维码关注我们</Text>
            </Row>
          </Col>
        </Row>
      </Card >
    </>
  )
}




function JoinusPage() {
  const columns = [
    {
      title: '职位名称', dataIndex: 'title', key: 'key', render: (_, record) => (
        <Space size="middle">
          <Link to={`/joinus/${record.key}`}>{record.title}</Link>
        </Space>
      )
    },
    { title: '工作地点', dataIndex: 'location', key: 'location', },
    { title: '发布时间', dataIndex: 'date', key: 'date', sorter: (a, b) => a.date - b.date, },
  ];
  return (
    <>
      <Row justify="center">
        <Col >
          <Image
            preview={false}
            width="100%"
            src="/tenet.png"
          />
        </Col>
      </Row>
      <Divider />
      <Paragraph>
        江原科技 来自AMD/华为/平头哥/联发科的创业团队正在招聘，其中研发团队和企业文化来自AMD，有（创业公司中为数不多的）计算大芯片成功量产及商业落地的经验（BAT级别客户）。
      </Paragraph>
      <Paragraph>
        加入我们，一起做大算力芯片！
      </Paragraph>
      <Paragraph style={{ marginTop: '18px', whiteSpace: 'pre-wrap' }}>投递简历:  <MailFilled /> <Link to="mailto:job@enrigin.com.cn">job@enrigin.com.cn</Link></Paragraph>
      <Table columns={columns} dataSource={jdData} />
    </>
  )
}
function JoinUsSubPage() {
  const { id } = useParams();
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  const paragraphStyle = {
    marginTop: '18px',
    whiteSpace: 'pre-wrap',
    paddingLeft: '16px'
  }
  const jdItem = jdData.find(item => item.key === id);
  if (!jdItem) {
    return <div>JD not found</div>;
  }
  return (
    <Row>
      <Col offset={2} span={16}>
        <Title level={2}>{jdItem.title}</Title>
        <Paragraph ><blockquote><EnvironmentFilled /> 工作地点：{jdItem.location}</blockquote></Paragraph>
        <Paragraph ><blockquote><ClockCircleFilled /> 发布时间：{jdItem.date}</blockquote></Paragraph>
        <Paragraph ><blockquote><MailFilled /> <Link to="mailto:job@enrigin.com.cn">job@enrigin.com.cn</Link></blockquote></Paragraph>
        工作职责：<Paragraph style={paragraphStyle} >{jdItem.responsibility}</Paragraph>
        岗位要求：<Paragraph style={paragraphStyle} >{jdItem.requirements}</Paragraph>
      </Col>
    </Row>
  );
};

function InfoPage() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  const paragraphStyle = {
    whiteSpace: 'pre-wrap',
    textIndent: '0em',
    fontSize: '16px',
  }
  const titleFlagStyle = { color: '#8c8c8c', borderLeft: '5px solid #1677ff', padding: '20px 10px ', marginBottom: '26px', backgroundColor: '#f0f2f5', width: '80%', }
  const teamCardStyle = { display: 'block', width: '80%', padding: "20px", margin: "20px", height: '300px' }
  const teamCardStyles = { body: { padding: 0, overflow: 'hidden', }, }
  const teamCardImageStyle = { display: 'block', width: 300, }
  return (
    <>
      <Row justify="center">
        <Col >
          <Image
            preview={false}
            width="100%"
            // height={360}
            src="/banner-2.jpg"
          />
        </Col>
      </Row>
      <Typography>
        <Divider />
        <Title
          id="aboutus"
          level={4}
          style={titleFlagStyle}
        >关于我们</Title>
        <Row>
          <Col span={18}>
            <Paragraph>
              江原科技成立于2022年11月，致力于实现我国人工智能芯片供应链自主可控，成为国内领先的“全国产化生产、制造和全内循环”芯片企业，实现中国芯，中国造。公司核心成员来自AMD、英伟达等国内外龙头芯片企业，截止目前，公司已打造了一支百人规模的团队，其中研发人员占比90%以上。公司在北京、上海、杭州分别设有研发中心。致力成为异构计算的领军企业，通过芯片、软件和系统一体化设计，打造领先的异构融合计算平台与技术生态。
            </Paragraph>
          </Col>
        </Row>
      </Typography>
      <Typography>
        <Divider style={{ border: 'none', margin: '20px 0' }} />
        <Title
          id="vision"
          level={4}
          style={titleFlagStyle}
        >企业愿景</Title>
        <Row>
          <Col span={18}>
            <Paragraph>
              打造国内领先、世界一流的智能计算系统和产业生态
            </Paragraph>
          </Col>
        </Row>
      </Typography>
      <Typography>
        <Divider style={{ border: 'none', margin: '20px 0' }} />
        <Title id="contact" level={4} style={titleFlagStyle}>联系我们</Title>
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '80%' }}>
          <Card>
            <Row justify="left" align="middle">
              <Col span={15}>
                <Row align="left" justify="space-between" wrap={false} style={{ backgroundColor: "#f0f0f0", borderRadius: '10px', paddingTop: "10px" }}>
                  <Col >
                    <Paragraph style={paragraphStyle}><EnvironmentFilled />上海办公室</Paragraph>
                    <Paragraph style={paragraphStyle}><EnvironmentFilled />北京办公室</Paragraph>
                    <Paragraph style={paragraphStyle}><MailFilled /> <Link to="mailto:info@enrigin.com.cn">info@enrigin.com.cn</Link></Paragraph>
                  </Col>
                  <Col offset={0}>
                    <Paragraph style={paragraphStyle}>上海市浦东新区郭守敬路498号1号楼302室</Paragraph>
                    <Paragraph style={paragraphStyle}>北京市海淀区知春路23号量子银座12层1209室</Paragraph>
                  </Col>
                  {/* <Col span={7}> */}

                  {/* </Col> */}
                </Row>
              </Col>
              <Col span={1}><Divider type='vertical' style={{
                height: 300,
              }} /></Col>

              <Col span={6} >
                <Row justify="center" align="middle" style={{ textAlign: 'center' }}>
                  <Image
                    preview={false}
                    src="/QRcode.jpg"
                    style={{ width: '80%' }}
                  />
                  <Paragraph style={{ marginTop: '10px' }}>扫描二维码关注我们</Paragraph>
                </Row>
              </Col>
            </Row>
          </Card >
          {/* {managerData.map((manager, index) => { */}
          {/* const isOdd = index % 2 === 1; */}
          {/* return ( */}
          {/* <Card key={index} hoverable style={teamCardStyle} styles={teamCardStyles}> */}
          {/* <Flex justify={isOdd ? 'space-between' : 'left'}> */}
          {/* <Flex justify={'left'}> */}
          {/* {!isOdd && <img alt="avatar" src={manager.avatar} style={teamCardImageStyle} />} */}
          {/* <Flex vertical style={{ padding: 32 }}> */}
          {/* <Meta title={manager.name} description={manager.title} /> */}
          {/* <Paragraph style={{ fontSize: '14px', color: 'gray', marginTop: '18px', whiteSpace: 'pre-wrap' }}>{manager.description}</Paragraph> */}
          {/* </Flex> */}
          {/* {isOdd && <img alt="avatar" src={manager.avatar} style={teamCardImageStyle} />} */}
          {/* </Flex> */}
          {/* </Card> */}
          {/* ); */}
          {/* })} */}
        </div>
      </Typography>


    </>
  )
}

function ComplianceStatementPage() {
  const csItem = csData.find(item => item.key === "1");
  const paragraphStyle = {
    marginTop: '18px',
    whiteSpace: 'pre-wrap',
    textIndent: '2em'
  }

  if (!csItem) {
    return <div>csItem item not found</div>;
  }
  // const paragraphs = csItem.content.split('\n');
  return (
    <Row>

      <Col offset={2} span={16}>
        <Title level={2}>{csItem.title}</Title>
        {/* 使用dangerouslySetInnerHTML直接渲染包含HTML的content */}
        <div dangerouslySetInnerHTML={{ __html: csItem.content }} />
      </Col>
    </Row>

  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<><HeaderCom /><HomePage /><FooterCom /></>} />
        <Route path="/info" element={<><HeaderCom /><ContentWrapper stuff={InfoPage} /><FooterCom /></>} />
        {/* <Route path="/contact" element={<><HeaderCom /><ContentWrapper stuff={ContactPage} /><FooterCom /></>} /> */}
        <Route path="/joinus" element={<><HeaderCom /><ContentWrapper stuff={JoinusPage} /><FooterCom /></>} />
        <Route path="/joinus/:id" element={<><HeaderCom /><ContentWrapper stuff={JoinUsSubPage} /><FooterCom /></>} />
        <Route path="/news/:id" element={<><HeaderCom /><ContentWrapper stuff={NewsSubPage} /><FooterCom /></>} />
        <Route path="/news" element={<><HeaderCom /><ContentWrapper stuff={NewsPage} /><FooterCom /></>} />
        <Route path="/cs" element={<><HeaderCom /><ContentWrapper stuff={ComplianceStatementPage} /><FooterCom /></>} />
        <Route path="*" element={<><HeaderCom /><ContentWrapper /><FooterCom /></>} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;